var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-wrap py-2"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.getFacturas(1)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Recargar ")],1),(!_vm.codigoContrato && _vm.$root.acceso('DEV'))?_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","to":"/facturacion/remesas"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-bank")]),_vm._v("Remesas ")],1):_vm._e(),_c('BtnFiltros',{attrs:{"value":_vm.estadosFiltros,"items":_vm.facturas,"filter-key":"estado"}}),_c('v-spacer'),(!_vm.codigoContrato)?_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.getFacturas(0)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-bank")]),_vm._v("Cargar remesadas ")],1):_vm._e()],1),_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"fixed-header":"","items":_vm.facturasFiltradas,"headers":_vm.facturasHeader,"show-select":"","item-key":"IdCarteraCobro","single-select":false,"calculate-widths":true,"search":_vm.search,"loading":_vm.loading,"footer-props":{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				},"checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
				var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.facturasFiltradas},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.estado",fn:function(ref){
				var item = ref.item;
return [_c('status-chip',{attrs:{"value":(item.estado)}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var on = ref.on;
				var attrs = ref.attrs;
return [(item.estado != 'Impagado' && item.FechaImpagado)?_c('v-icon',_vm._g(_vm._b({attrs:{"right":"","x-small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v("mdi-currency-eur-off")]):_vm._e()]}}],null,true)},[_vm._v(" Impago desde "+_vm._s(_vm.parseDate(item.FechaImpagado))+" hasta "+_vm._s(item.NFracciones > 0 ? ("Fraccionado " + (item.NFracciones) + " Pagos") : _vm.parseDate(item.FechaCobro || item.FechaComienzoRemesa))+" ")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
				var attrs = ref.attrs;
				var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 ml-2 font-weight-bold",staticStyle:{"min-width":"unset"},attrs:{"label":"","small":"","text":"","color":item.colorCliente},on:{"click":function($event){_vm.inlineFilters.Identificador == ('=' + item.Identificador) ? _vm.$delete(_vm.inlineFilters, 'Identificador') : _vm.$set(_vm.inlineFilters, 'Identificador', ('=' + item.Identificador))}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.iniciales))])]}}],null,true)},[_vm._v(" "+_vm._s(item.denominacion)+" ")])]}},{key:"item.FechaFactura",fn:function(ref){
				var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaFactura))}})]}},{key:"item.FechaVencimiento",fn:function(ref){
				var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaVencimiento))}})]}},{key:"item.FechaComienzoRemesa",fn:function(ref){
				var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaComienzoRemesa))}})]}},{key:"item.FechaLecturaAnteriorXML",fn:function(ref){
				var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaLecturaAnteriorXML))}})]}},{key:"item.FechaLecturaActualXML",fn:function(ref){
				var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.FechaLecturaActualXML))}})]}},{key:"item.ImporteNominal",fn:function(ref){
				var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(parseFloat(item.ImporteNominal).toFixed(2) + ' €')}})]}},{key:"item.ImporteGasto",fn:function(ref){
				var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(((parseFloat(item.ImporteGasto).toFixed(2)) + " €"))}})]}},{key:"item.ImporteTotal",fn:function(ref){
				var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(parseFloat(item.ImporteTotal).toFixed(2) + ' €')}})]}},{key:"item.tarifa",fn:function(ref){
				var item = ref.item;
return [_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(item.tarifa),expression:"item.tarifa"}],attrs:{"label":"","small":""},domProps:{"textContent":_vm._s(item.tarifa)}})]}},{key:"item.NumeroFactura",fn:function(ref){
				var item = ref.item;
return [_c('table-button',{attrs:{"tooltip":("Ver PDF factura " + (item.estimada ? '(estimada)' : '')),"disabled":!!_vm.pdfId || !item.IdDocumento,"color":"secondary","loading":_vm.pdfId == item.IdDocumento && _vm.pdfId !== null,"icon-right":item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'},on:{"click":function($event){$event.stopPropagation();return _vm.verPDFfactura(item.IdDocumento)}}},[_vm._v(" "+_vm._s(item.SerieFactura)+" "+_vm._s(item.NumeroFactura)+" ")])]}},{key:"item.NumeroFacturaRoot",fn:function(ref){
                  var item = ref.item;
return [_c('table-button',{attrs:{"tooltip":("Ver PDF factura " + (item.estimada ? '(estimada)' : '')),"disabled":!!_vm.pdfId || !item.IdDocumento,"color":"secondary","loading":_vm.pdfId == item.IdDocumento && _vm.pdfId !== null,"icon-right":item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'},on:{"click":function($event){$event.stopPropagation();return _vm.verPDFfactura(item.IdDocumento)}}},[_vm._v(" "+_vm._s(item.SerieFacturaRoot)+" "+_vm._s(item.NumeroFacturaRoot)+" ")])]}},{key:"item.NumeroFraccion",fn:function(ref){
                  var item = ref.item;
return [(item.NumeroFraccion)?_c('div',{staticClass:"d-flex"},_vm._l((Number(item.NFracciones)),function(i){return _c('v-icon',{key:i,attrs:{"color":['primary darken-3', 'primary darken-2', 'primary darken-1', 'primary'][i - 1]}},[_vm._v(_vm._s(Number(item.NumeroFraccion) >= i ? 'mdi-circle' : 'mdi-circle-outline'))])}),1):(Number(item.NFracciones))?_c('status-chip',{attrs:{"small":"","value":("Fraccionado " + (item.NFracciones) + " Pagos")}}):_vm._e()]}},{key:"item.CodigoContrato",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":("/contratos/detalles?codigoContrato=" + (item.CodigoContrato) + "&idContrato=" + (item.IdContrato)),"color":"secondary","text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(Math.abs(Number(item.CodigoContrato))))])]}}],null,true)},[_c('span',[_vm._v("Detalles del contrato")])])]}},{key:"item.Identificador",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"link":"","to":("/clientes/detalles?idCliente=" + (item.IdCliente)),"color":"secondary","text":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(item.Identificador))])]}}],null,true)},[_c('span',[_vm._v("Detalles del cliente")])])]}},{key:"item.acciones",fn:function(ref){
                  var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){_vm.detailsId = [item.IdCarteraCobro, item.IdFacturaVentaCabecera]}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_vm._v(" Ver detalles ")])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('div',{staticClass:"d-flex flex-wrap mt-4 align-center"},[_c('strong',{staticClass:"mr-2"},[_vm._v("Total importes "+_vm._s(_vm.selected.length == 0 ? 'registros filtrados' : 'registros seleccionados')+" :")]),_vm._v(" "+_vm._s(_vm.acumuladoFacturas)+" "),_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","color":"primary","small":"","disabled":_vm.selected.length == 0},on:{"click":function($event){_vm.selected = []}}},[_vm._v("Deseleccionar todos ("+_vm._s(_vm.selected.length)+")")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"color":"primary","text":"","disabled":_vm.selected.length <= 0},on:{"click":function($event){$event.stopPropagation();_vm.selected.forEach(function (ref) {
                                  var IdDocumento = ref.IdDocumento;

                                  return _vm.verPDFfactura(IdDocumento, false);
                  })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-file-download-outline")]),_vm._v("Descargar PDFs ")],1)],1),_c('v-dialog',{attrs:{"value":_vm.detailsId[0],"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},on:{"input":function (v) { return v === false ? _vm.detailsId = [] : null; }}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","color":"primary"}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Detalles factura Cod "+_vm._s(_vm.detailsId[0]))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"white","icon":""},on:{"click":function($event){_vm.detailsId = []}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Control factura")]),_c('v-tab',[_vm._v("Detalles factura")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.detailsId[0])?_c('detalles-impagados',{key:_vm.detailsId[0],attrs:{"IdCarteraCobro":_vm.detailsId[0]},on:{"changeIdCarteraCobro":function (v) { return _vm.detailsId = v; },"close":function($event){_vm.detailsId = []}}}):_vm._e()],1),_c('v-tab-item',[(_vm.detailsId[0])?_c('detalles-factura',{key:_vm.detailsId[0],attrs:{"IdCarteraCobro":_vm.detailsId[0]},on:{"close":function($event){_vm.detailsId = []}}}):_vm._e()],1)],1)]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { parseDate, timeAgo } from "@/utils/index.js";
import palette from "./colorPalette.js";

export function getEstado(CodigoSituacionCobro) {
  switch (Number(CodigoSituacionCobro)) {
    case -2: return "Gesico";
    case -3: return "Pérdidas";
    case 0: return "Sin Especificar";
    case 1: return "Pendiente";
    case 2: return "Cobrado";
    case 3: return "Impagado";
    case 4: return "Remesado";
    case 5: return "Carta Aviso";
    case 6: return "Carta Corte";
    case 7: return "Cortado";
    case 8: return "Cobrado norma 57";
    case 9: return "Cancelado por abono";
    case 10: return "Carta Corte 1";
    case 11: return "Carta Deuda";
    case 12: return "Fraccionado";
  }
}


export function parseImpagados(impagados) {
  return impagados.map((f) => {
    f.estado = getEstado(f.CodigoSituacionCobro);

    f.denominacion =
      f.RazonSocialTitular ||
      [f.Apellido1Titular, f.Apellido2Titular, f.NombreTitular]
        .filter((i) => !!i)
        .join(" ")
        .trim();

    f.iniciales = [
      (f.NombreTitular || "")[0],
      (f.Apellido1Titular || "")[0],
      (f.RazonSocialTitular || "").slice(0, 2),
    ].join("");

    f.diasDesdeVencimiento = (() => {
      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0);
      const imp = new Date(
        parseDate(f.estadoPropioVencimiento || f.FechaVencimiento, true)
      );
      imp.setHours(0, 0, 0, 0);
      return Math.round((hoy.getTime() - imp.getTime()) / (1000 * 3600 * 24));
    })();

    f.diasDesdeEmision = (() => {
      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0);
      const imp = new Date(
        parseDate(f.FechaFactura, true)
      );
      imp.setHours(0, 0, 0, 0);
      return Math.round((hoy.getTime() - imp.getTime()) / (1000 * 3600 * 24));
    })();

    f.diasDesdeImpagado = (() => {
      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0);
      const imp = new Date(parseDate(f.FechaImpagado, true));
      imp.setHours(0, 0, 0, 0);
      return Math.round((hoy.getTime() - imp.getTime()) / (1000 * 3600 * 24));
    })();

    if (f.superior10KW) {
      f.envioCarta1 = null;
      f.envioCarta2 = null;
      f.envioCarta3 = parseDate(f.FechaImpagado, true) + 1000 * 60 * 60 * 24 * 8;
    } else {
      f.envioCarta1 = parseDate(f.FechaImpagado, true) + 1000 * 60 * 60 * 24 * 21;
      f.envioCarta2 = f.envioCarta1 + 1000 * 60 * 60 * 24 * 7;
      f.envioCarta3 = f.envioCarta2 + 1000 * 60 * 60 * 24 * 7;
    }

    if ( f.situacionContrato == 'Baja' ) {
      f.envioCarta1 = null;
      f.envioCarta2 = null;
      f.envioCarta3 = null;
    }

    if (!parseDate(f.FechaImpagado, true)) {
      f.envioCarta1 = null;
      f.envioCarta2 = null;
      f.envioCarta3 = null;

      f.FechaTransferencia = f.FechaComienzoRemesa;
    }

    f.carta1Bool = !!f.carta1 && f.envioCarta1 !== null;
    f.carta2Bool = !!f.carta2 && f.envioCarta2 !== null;
    f.cartaCorteBool = !!f.cartaCorte && f.envioCarta3 !== null;

    if (f.amenazaCorte) {
      f.corteEn = (parseDate(f.amenazaCorte));
    }

    f.amenazaCorteBool = !!f.amenazaCorte;

    f.historico = f.estado != "Impagado" && !( f.FechaTransferencia && f.estado == "Pendiente" && f.TextoTipoCobro == "Transferencia" );
    f.futuro = !f.FechaCobro && parseDate(f.FechaComienzoRemesa, true) >= new Date().getTime();

    f.fechaRecuperacion = (f.historico && !f.futuro && f.estado != "Impagado" && f.estado != "Pendiente") ? (f.FechaCobro || f.FechaComienzoRemesa) : null;

    f.aplazado = false;

    f.NImpagadosCliente = impagados.filter(
      (imp) => imp.IdCliente == f.IdCliente
    ).length;

    f.colorCliente =
      palette[
        ((f.IdCliente % palette.length) + palette.length) % palette.length
      ];

    return f;
  });
}

<template>
	<v-container fluid>
		<div class="d-flex flex-wrap py-2">
			<v-btn class="ma-1" color="primary" small @click="getFacturas(1)">
				<v-icon left>mdi-reload</v-icon>Recargar
			</v-btn>
			<v-btn class="ma-1" color="primary" small v-if="!codigoContrato && $root.acceso('DEV')" to="/facturacion/remesas">
				<v-icon left>mdi-bank</v-icon>Remesas
			</v-btn>

			<!-- <v-btn
				v-for="filtro in Object.keys(estadosFiltros)"
				:key="filtro"
				small
				text
				:class="{ 'ma-1': true, 'v-btn--active': estadosFiltros[filtro] }"
				:color="getSituacionIcon(filtro).color"
				@click.stop="estadosFiltros[filtro] = !estadosFiltros[filtro]"
				:disabled="facturas.filter(imp => imp.estado == filtro).length == 0"
			>
				<v-icon left>{{ getSituacionIcon(filtro).icon }}</v-icon>
				{{ facturas.filter(imp => imp.estado == filtro).length }} {{ filtro }}
			</v-btn> -->

			<BtnFiltros :value="estadosFiltros" :items="facturas" filter-key="estado" ></BtnFiltros>

			<v-spacer></v-spacer>

			<v-btn color="grey" text v-if="!codigoContrato" @click="getFacturas(0)">
				<v-icon left>mdi-bank</v-icon>Cargar remesadas
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				fixed-header
				:items="facturasFiltradas"
				:headers="facturasHeader"
				show-select
				class="fixed-checkbox"
				item-key="IdCarteraCobro"
				v-model="selected"
				:single-select="false"
				:calculate-widths="true"
				:search="search"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
				checkbox-color="secondary"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="facturasFiltradas" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.estado="{ item }">
					<status-chip :value="(item.estado)" />
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-icon
								v-if="item.estado != 'Impagado' && item.FechaImpagado"
								v-on="on"
								v-bind="attrs"
								right
								x-small
								color="error"
							>mdi-currency-eur-off</v-icon>
						</template>
						Impago desde {{ parseDate(item.FechaImpagado) }} hasta
						{{ item.NFracciones > 0 ? `Fraccionado ${item.NFracciones} Pagos` : parseDate(item.FechaCobro || item.FechaComienzoRemesa) }}
					</v-tooltip>
					<v-tooltip right>
						<template v-slot:activator="{ attrs, on }">
							<v-btn
								v-bind="attrs"
								v-on="on"
								label
								small
								text
								:color="item.colorCliente"
								class="pa-1 ml-2 font-weight-bold"
								style="min-width: unset"
								@click="inlineFilters.Identificador == ('=' + item.Identificador) ? $delete(inlineFilters, 'Identificador') : $set(inlineFilters, 'Identificador', ('=' + item.Identificador))"
							>{{ item.iniciales }}</v-btn>
						</template>
						{{ item.denominacion }}
					</v-tooltip>
				</template>

				<template v-slot:item.FechaFactura="{ item }">
					<span v-text="parseDate(item.FechaFactura)"></span>
				</template>
				<template v-slot:item.FechaVencimiento="{ item }">
					<span v-text="parseDate(item.FechaVencimiento)"></span>
				</template>
				<template v-slot:item.FechaComienzoRemesa="{ item }">
					<span v-text="parseDate(item.FechaComienzoRemesa)"></span>
				</template>
				<template v-slot:item.FechaLecturaAnteriorXML="{ item }">
					<span v-text="parseDate(item.FechaLecturaAnteriorXML)"></span>
				</template>
				<template v-slot:item.FechaLecturaActualXML="{ item }">
					<span v-text="parseDate(item.FechaLecturaActualXML)"></span>
				</template>

				<template v-slot:item.ImporteNominal="{ item }">
					<span v-text="parseFloat(item.ImporteNominal).toFixed(2) + ' €'" />
				</template>
				<template v-slot:item.ImporteGasto="{ item }">
					<span v-text="`${parseFloat(item.ImporteGasto).toFixed(2)} €`" />
				</template>
				<template v-slot:item.ImporteTotal="{ item }">
					<span class="font-weight-bold" v-text="parseFloat(item.ImporteTotal).toFixed(2) + ' €'" />
				</template>

				<template v-slot:item.tarifa="{ item }">
          <v-chip v-show="item.tarifa" label small v-text="item.tarifa" />
        </template>

				<template v-slot:item.NumeroFactura="{ item }">
          <table-button
              :tooltip="`Ver PDF factura ${ item.estimada ? '(estimada)' : '' }`"
              :disabled="!!pdfId || !item.IdDocumento"
              color="secondary"
              @click.stop="verPDFfactura(item.IdDocumento)"
              :loading="pdfId == item.IdDocumento && pdfId !== null"
              :icon-right="
                item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'
              "
            >
              {{ item.SerieFactura }}
              {{ item.NumeroFactura }}
            </table-button>
        </template>

        <template v-slot:item.NumeroFacturaRoot="{ item }">
            <table-button
              :tooltip="`Ver PDF factura ${ item.estimada ? '(estimada)' : '' }`"
              :disabled="!!pdfId || !item.IdDocumento"
              color="secondary"
              @click.stop="verPDFfactura(item.IdDocumento)"
              :loading="pdfId == item.IdDocumento && pdfId !== null"
              :icon-right="
                item.estimada ? 'mdi-help-rhombus-outline' :
                item.IdDocumento
                  ? 'mdi-file-document-edit-outline'
                  : 'mdi-file-cancel-outline'
              "
            >
              {{ item.SerieFacturaRoot }}
              {{ item.NumeroFacturaRoot }}
            </table-button>
        </template>

				<template v-slot:item.NumeroFraccion="{ item }">
					<div class="d-flex" v-if="item.NumeroFraccion">
						<v-icon
							:key="i"
							v-for="i in Number(item.NFracciones)"
							:color="['primary darken-3', 'primary darken-2', 'primary darken-1', 'primary'][i - 1]"
						>{{ Number(item.NumeroFraccion) >= i ? 'mdi-circle' : 'mdi-circle-outline' }}</v-icon>
					</div>
					<status-chip
						v-else-if="Number(item.NFracciones)"
						small
						:value="`Fraccionado ${item.NFracciones} Pagos`"
					></status-chip>
				</template>

				<template v-slot:item.CodigoContrato="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								:to="`/contratos/detalles?codigoContrato=${item.CodigoContrato}&idContrato=${item.IdContrato}`"
								color="secondary"
								text
							>{{ Math.abs(Number(item.CodigoContrato)) }}</v-btn>
						</template>
						<span>Detalles del contrato</span>
					</v-tooltip>
				</template>

				<template v-slot:item.Identificador="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								:to="`/clientes/detalles?idCliente=${item.IdCliente}`"
								color="secondary"
								text
							>{{ item.Identificador }}</v-btn>
						</template>
						<span>Detalles del cliente</span>
					</v-tooltip>
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								@click="detailsId = [item.IdCarteraCobro, item.IdFacturaVentaCabecera]"
								icon
								color="secondary"
							>
								<v-icon>mdi-eye</v-icon>
							</v-btn>
						</template>
						Ver detalles
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card>

		<div class="d-flex flex-wrap mt-4 align-center">
			<strong
				class="mr-2"
			>Total importes {{ selected.length == 0 ? 'registros filtrados' : 'registros seleccionados' }} :</strong>
			{{ acumuladoFacturas }}
			<v-btn
				text
				color="primary"
				class="ml-2"
				small
				:disabled="selected.length == 0"
				@click="selected = []"
			>Deseleccionar todos ({{ selected.length }})</v-btn>
		
		<v-spacer></v-spacer>

		<v-btn
			color="primary"
			text
			:disabled="selected.length <= 0"
			@click.stop="selected.forEach(({ IdDocumento }) => verPDFfactura(IdDocumento, false))"
			class="mr-2"
		>
      <v-icon left>mdi-file-download-outline</v-icon>Descargar PDFs
    </v-btn>
		
		</div>

		<v-dialog
			:value="detailsId[0]"
			@input="(v) => v === false ? detailsId = [] : null"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card>
				<v-toolbar dense color="primary">
					<v-toolbar-title class="white--text">Detalles factura Cod {{ detailsId[0] }}</v-toolbar-title>
					<v-spacer></v-spacer>

					<v-btn color="white" icon @click="detailsId = []">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>

				<template>
					<v-tabs v-model="tab">
						<v-tab>Control factura</v-tab>
						<!-- <v-tab>Histórico llamadas</v-tab> -->
						<v-tab>Detalles factura</v-tab>
					</v-tabs>

					<v-tabs-items v-model="tab">
						<v-tab-item>
							<detalles-impagados
								@changeIdCarteraCobro="v => detailsId = v"
								@close="detailsId = []"
								:IdCarteraCobro="detailsId[0]"
								v-if="detailsId[0]"
								:key="detailsId[0]"
							/>
						</v-tab-item>
						<!-- <v-tab-item>
							<historico-llamadas
								@close="detailsId = []"
								:IdCarteraCobro="detailsId[0]"
								v-if="detailsId[0]"
								:key="detailsId[0]"
							/>
						</v-tab-item>-->
						<v-tab-item>
							<detalles-factura
								@close="detailsId = []"
								:IdCarteraCobro="detailsId[0]"
								v-if="detailsId[0]"
								:key="detailsId[0]"
							/>
						</v-tab-item>
					</v-tabs-items>
				</template>
			</v-card>
		</v-dialog>

		<!-- <v-dialog
			:value="detailsId"
			@input="(v) => v === false ? detailsId = null : null"
			fullscreen
			hide-overlay
			transition="dialog-bottom-transition"
		>
			<v-card class="rounded-0">
				<v-toolbar dense color="primary" class="white--text">
					<v-toolbar-title>Detalles Factura</v-toolbar-title>
					<v-spacer />
					<v-btn color="white" icon @click="detailsId = null">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text>
					<detalles-facturacion :idFactura="detailsId" v-if="detailsId" :key="detailsId"></detalles-facturacion>
				</v-card-text>
			</v-card>
		</v-dialog>-->
	</v-container>
</template>

<script>
import { getSituacionIcon, perColumnFilter, parseDate, downloadFile } from "@/utils/index.js";
import { parseImpagados } from "@/modules/impagados/services/parseImpagados";
// import palette from "@/modules/impagados/services/colorPalette.js";

export default {
	components: {
		StatusChip: () => import("@/components/StatusChip.vue"),
		TableFilters: () => import("@/components/TableFilters.vue"),
		TableButton: () => import("@/components/TableButton.vue"),
		BtnFiltros: () => import("@/components/BtnFiltros.vue"),
		DetallesFactura: () => import("./DetallesFactura.vue"),
		DetallesImpagados: () => import("@/modules/impagados/views/DetallesImpagados.vue"),
		HistoricoLlamadas: () => import("@/modules/impagados/views/HistoricoLlamadas.vue"),
	},
	props: ['codigoContrato'],
	computed: {
		acumuladoFacturas() {
			if (this.selected.length) {
				return parseFloat(this.selected.reduce((a, c) => a + Number(c.ImporteTotal), 0)).toFixed(2) + ' €'
			} else {
				// return parseFloat(this.facturas.reduce((a, c) => a + Number(c.ImporteTotal), 0)).toFixed(2) + ' €'
				const getDatatype = (key) =>
					(this.facturasHeader.find((h) => h.value == key) || {}).dataType;

				return parseFloat(this.facturas.filter((con) =>
					Object.entries(con).every(([key, value]) =>
						perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
					)
				).map(i => Number(i.ImporteTotal)).reduce((a, c) => a + c, 0)).toFixed(2) + ' €'
			}
		},
		facturasFiltradas() {
			return this.facturas.filter((imp) => {
				// const [key, value] = Object.entries(this.estadosFiltros)
				for (let [key, value] of Object.entries(this.estadosFiltros))
					if (imp.estado == key) return value;
				return true;
			})
		}
	},
	data() {
		return {
			debounce: true,
			loading: true,
			page: 2,
			selected: [],
			tab: 0,
			search: null,
			detailsId: [],
			pdfId: false,
			facturas: [],
			facturasHeader: [
				{ text: "Estado", value: "estado", dataType: 'select' },
				{
					text: "Acciones",
					value: "acciones",
					sortable: false,
					filterable: false,
				},
				{ text: "Total", value: "ImporteTotal" },
				{ text: "NºDoc Actual", value: "NumeroFactura" },
				{ text: "NºDoc Original", value: "NumeroFacturaRoot" },
				{ text: "Fecha", dataType: "date", value: "FechaFactura" },
				{ text: "Fecha Vencimiento", dataType: "date", value: "FechaVencimiento" },
				{ text: "Fecha Comienzo Remesa", dataType: "date", value: "FechaComienzoRemesa" },
				{ text: "Canal", dataType: "select", value: "Canal" },
				{ text: "Fraccion", value: 'NumeroFraccion' },
				{ text: "Agente/Canal", value: "CanalAgente" },
        { text: "SubAgente", value: "SubAgente" },
				{ text: "Contrato", value: "CodigoContrato" },
				{ text: "Cliente", value: "Identificador" },
				{ text: "Nombre cliente", value: "denominacion" },
				// { text: "Detalles", value: "detalles" },
				// { text: "Tipo lectura", dataType: 'select', value: "tipoLectura" },
				{ text: "Lectura Anterior", dataType: 'date', value: "FechaLecturaAnteriorXML" },
				{ text: "Fecha Lectura", dataType: 'date', value: "FechaLecturaActualXML" },
        { text: "Tarifa", value: "tarifa", align: "center", dataType: "select", },
			].map((header) =>
			({
				class: "text-no-wrap sticky-header",
				cellClass: "pa-2 text-no-wrap",
				filter: (value) =>
					perColumnFilter(
						value,
						this.inlineFilters[header.value],
						header.dataType
					),
				dataType: "text",
				...header,
			})
			),

			estadosFiltros: {
				'Pendiente': !!this.codigoContrato || false,
				'Cancelado por abono': !!this.codigoContrato || false,
				'Impagado': !!this.codigoContrato || false,
				'Fraccionado': !!this.codigoContrato || false,
			},

      inlineFilters: Object.keys(this.$route.query).length > 0 ? Object.fromEntries( Object.entries(this.$route.query).map(([key, value]) => [ key, "=" + value, ]) ) : {},

			comboboxes: {},
			loadingPDF: null,
		};
	},
	methods: {
		getSituacionIcon,
		parseDate,
		async getFacturas(quitarRemesadas = 1) {

			if (!this.$root.acceso('VER_FACTURAS_CONTRATOS') && !this.codigoContrato) {
				this.$router.push({ name: 'Contratos' })
				return
			};

			if (this.codigoContrato) quitarRemesadas = 0

			this.loading = true;
			const { data: facturas } = await axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/facturacion.php`,
				method: 'GET',
				params: { token: this.$store.getters.getJwtEmpresa, codigoContrato: this.codigoContrato, quitarRemesadas: quitarRemesadas }
			});
			// console.log(facturas.map(({ IdFacturaVentaCabecera, IdRemesaCobro, IdRemesaImpagadoCobro }) => ({ IdFacturaVentaCabecera, IdRemesaCobro, IdRemesaImpagadoCobro })))
			this.facturas = parseImpagados(facturas)

			this.loading = false;
		},
		verPDFfactura(idDocumento, open = true) {
			if (idDocumento == null) return;
      this.pdfId = idDocumento;
      let str = this.$store.getters.getDatosEmpresa;
      const { IdUsuario } = str.iss;
      const IdEmpresa = this.$store.getters.getIdEmpresa;
      axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/FacturaVentaCabecera/FacturaPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${idDocumento}`,
        responseType: "blob",
      })
        .then((res) => {
          const {SerieFacturaRoot, NumeroFacturaRoot, denominacion} = this.facturasFiltradas.find(x => x.IdDocumento == idDocumento)
          let pdf = new File(
            [res.data],
            `${SerieFacturaRoot}-${NumeroFacturaRoot}-${denominacion}.pdf`,
            {
              type: "application/pdf",
            }
          );
          downloadFile(pdf, open);
          this.pdfId = null;
        })
        .catch((err) => {
          this.$root.$emit("snack", "Ha ocurrido un error");
          this.pdfId = null;
        });
    },
	},
	mounted() {
		this.getFacturas();
	},
};
</script>